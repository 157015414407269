<template>
    <div class="hardClassStudentDialog">
        <el-dialog title="学生列表" width="80%"  :visible.sync="dialogVisible" :close-on-click-modal="false">
            <el-row>
                <el-col :span="8" :offset="4">
                    <el-input v-model="searchForm.searchText" autocomplete="off"></el-input>
                </el-col>
                <el-col :span="2">
                    <el-button type="info" size ="mini" icon="search" @click='loadStudentList'>查询</el-button>
                </el-col>
            </el-row>
            <el-row style="margin-top: 20px;">
                <el-col :span="24">
                    <el-table v-loading="loading" :data="tableData" style="width: 100%" align='center'>
                        <el-table-column prop="my_no" label="工号"  width="150" align='center' ></el-table-column>
                        <el-table-column prop="name" label="姓名"  width="150" align='center' ></el-table-column>
                        <el-table-column prop="school_name" label="所属单位" align='center' ></el-table-column>
                        <el-table-column prop="hard_class_name" label="行政班" align='center' ></el-table-column>
                        <el-table-column prop="year" label="入学年" align='center' ></el-table-column>
                        <el-table-column prop="profession_name" label="专业" align='center' ></el-table-column>
                        <el-table-column prop="phone_no" label="联系方式" align='center' ></el-table-column>
                        <el-table-column prop="remark" label="备注" align='center' ></el-table-column>
                    </el-table>
                </el-col>

            </el-row>

            <div slot="footer" class="dialog-footer">
                <el-button @click="cancel">关 闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {mapGetters, mapState} from "vuex";
    import * as simpleApi from "request/simple";
    import XEUtils from 'xe-utils'
    import * as mUtils from '@/utils/mUtils'

    export default {
        name: 'hardClassStudentDialog',
        data(){
            return {
                formLabelWidth: '120px',
                dialogVisible:false, loading: false,
                hardClassData: [], selHardClassData: [],
                tableData: [],
                teachingClassId: null,
                searchForm:{ pageNo: 1, pageSize: 200, totalCount: 0, searchText: null, hardClassId: null, },
            }
        },
        components:{
        },
        computed:{
            ...mapState({
                userInfo: state => state.user.userInfo,
            }),
        },
      	mounted() {

	    },
        props: {
            hardClass: {
                type: Object
            }
        },
        watch: {
            hardClass: {
                deep: true, immediate:true,
                handler(n, o){
                    console.log(n)
                    if(n){
                        this.searchForm.hardClassId = n.id;
                        this.loadStudentList();
                    }
                }
            },
        },
        methods: {
            showDialog(){
                this.dialogVisible = true;
            },
            hideDialog(){
                this.dialogVisible = false;
            },
            cancel(){
                this.dialogVisible = false;
                this.$emit('cancel');
            },
            loadStudentList(){
                this.loading = true;

                let search = { searchText: mUtils.searchText(this.searchForm.searchText), licenceId: this.userInfo.licenceId,
                    schoolId: this.userInfo.schoolId, hardClassId: this.searchForm.hardClassId};
                let param = { controllerName: 'sclStudent', methodName: '/list', pageNo: 0, pageSize: 500, param: search};
                return simpleApi.list(param).then(({result: {code, data}}) =>  {
                    console.log(data)
                    if (data.code == 0) {
                        this.tableData = data.result;

                    }
                    this.loading = false;
                }).catch((error) => {
                    this.loading = false;
                    console.log("error")
                });
            },


        },
    }
</script>

<style lang="scss" type="text/scss" rel="stylesheet/scss">

    .hardClassStudentDialog{

    }


</style>


